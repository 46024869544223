import React from 'react';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';

function Collaboration() {
  return (
    <Layout>
      <SEO title="Collaboration " />
      <section className="inner-hero bg-lightgreen">
        <div className="container">
          <div className="inner-hero-content text-center">
            <h1 className="text-primary font-medium mb-2">Collaboration</h1>
          </div>
        </div>
      </section>

      <section className="section-gap">
        <div className="container">
          <div className="row">
            <div className="col-md col-8 offset-2">
              <div className="text-center">
                <p className="text-18">
                  EFFIPAC is a proven solution for the vulnerable senior
                  population living at home and fits for at-risk patients of any
                  age. Using personalized packing and delivery can ensure both
                  the patient and caregiver are taken care of with their
                  medication needs, keeping the most fragile patients safe,
                  healthy, and adherent. Not only can this type of service and
                  use of technology keep patients safe at home, but it can save
                  on total spending in the healthcare system.
                </p>
                <p className="text-18">
                  EFFIPAC is on a mission to solve some of the biggest
                  challenges faced by vulnerable populations living at home:
                  medication adherence, accuracy, and safety.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-gap">
        <div className="container">
          <h2 className="heading mb-5 text-center">Come join hands with us!</h2>
          <div className="row items-center">
            <div className="col col-6">
              <h3 className="heading-small text-orange mb-3">
                Benefits of collaborating with us
              </h3>

              <ul className="check-list">
                <li>It will improve medication adherence of your patients</li>
                <li> You can have 100% tracking of their consumption</li>
                <li> This will provide better patient care</li>
                <li> Effipac is a very easy-to-use solution</li>
              </ul>
            </div>

            <div className="col col-6">
              <img src="/img/collaboration.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="section-gap">
        <div className="container">
          <div className="row">
            <div className="col-md col-5">
              <h3 className="heading-smallest mb-4">
                Get in touch with our representative and know how we can
                integrate with your pharmacy or healthcare system.
              </h3>
            </div>
            <div className="col-md col-6 offset-1">
              <div className="flex flex-wrap gap-3">
                <div className="single-contact bg-lightgreen relative overflow-hidden rounded-lg w-full md:w-auto md:flex-1 text-center">
                  <div className="contact-icon absolute">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2Z"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="m22 6-10 7L2 6"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <a
                    href="mailto:info.effipac@gmail.com"
                    className="text-18 font-medium py-6 px-4 block">
                    info.effipac@gmail.com
                  </a>
                </div>
                <div className="single-contact bg-lightgreen relative overflow-hidden rounded-lg w-full md:w-auto md:flex-1 text-center">
                  <div className="contact-icon absolute">
                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none">
                      <path
                        d="M22 16.723v3.16a2.106 2.106 0 0 1-1.436 2.006 2.115 2.115 0 0 1-.866.102 20.921 20.921 0 0 1-9.113-3.235 20.569 20.569 0 0 1-6.335-6.322A20.827 20.827 0 0 1 1.01 3.297a2.104 2.104 0 0 1 1.25-2.117c.268-.118.558-.18.85-.18h3.168A2.114 2.114 0 0 1 8.39 2.813a13.51 13.51 0 0 0 .74 2.96 2.104 2.104 0 0 1-.476 2.224l-1.34 1.339a16.877 16.877 0 0 0 6.334 6.322l1.341-1.338a2.113 2.113 0 0 1 2.228-.474c.958.357 1.953.604 2.967.737A2.112 2.112 0 0 1 22 16.723Z"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <a
                    href="tel:88 0909 4343"
                    className="text-18 font-medium py-6 px-4  block">
                    88 0909 4343
                  </a>
                </div>
              </div>
            </div>{' '}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Collaboration;
